import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/chrissysimon.jpg'
import pic02 from '../images/anmeldung.png'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Grusswort des Brautpaars</h2>
          <span className="image main">
            <img src={pic01} alt="Foto des Brautpaars" />
          </span>
          <p>

          </p>
          <p>
          Christina &amp; Simon
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Zur Bildergalerie</h2>
          <span className="image main">
            <img src={pic02} alt="Anmeldesymbol Beispiel" />
          </span>
          <p>
            In unserer Bildergalerie könnt Ihr Eure Erinnerungen auffrischen und Euch durch die Hochzeitsfotos klicken.</p>
          <p>
            Meldet Euch am System an, um Zugriff auf die Alben zu erhalten. Klickt dazu <strong>auf das Symbol links oben in der Ecke</strong> und gebt die Zugangsdaten ein, die Ihr von uns erhalten habt.
          </p>
          <h3><a href="https://fotos.hochzeit-christina-simon.de" >Hier geht es weiter...</a></h3>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
